@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');


.banner {
  padding-top: 70px;
  @media (max-width: 768px) {
  }
}

body {
  font-family: 'Montserrat';
  background: rgb(0,0,0);
  background: -moz-radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(15,0,68,1) 57%, rgba(0,0,0,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(15,0,68,1) 57%, rgba(0,0,0,1) 100%);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(15,0,68,1) 57%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.shake {
  animation: shake .5s;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.fade-in {
  animation: fade-in .45s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.planet {
  animation: float 6s ease-in-out infinite;
}

/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3809D1;
  border-radius: 50%;
}

.earth-orbit {
  width: 165px;
  height: 165px;
  -webkit-animation: spin 12s linear 0s infinite;
}

.venus-orbit {
  width: 120px;
  height: 120px;
  -webkit-animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
  width: 90px;
  height: 90px;
  -webkit-animation: spin 3s linear 0s infinite;
}

.planet-loading {
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.first {
    background-color: #FF1474;
  }

  &.second {
    background-color: #00E676;
  }

  &.third {
    background-color: #7806D4;
  }
}

.sun {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(255,135,13);
  background: radial-gradient(circle, rgba(255,135,13,1) 0%, rgba(255,19,117,1) 100%);
}


.leo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.blue-orbit {
  width: 165px;
  height: 165px;
  border: 1px solid #91daffa5;
  -webkit-animation: spin3D 3s linear .2s infinite;
}


.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
}


.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}

@keyframes fadeOut {
   0% {
     opacity:1;
   }
   100% {
     opacity:0;
   }
 }

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



.react-multi-carousel-dot {

  &.react-multi-carousel-dot--active {
    button {
      background-color: #FF1474 !important;
    }
  }
  button {
    background-color: rgba(56,9,209,0.5);
    border: none !important;
  }

}

.react-multiple-carousel__arrow::before{
  color: #FF1474 !important;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
}

.react-multi-carousel-item  {
  display: flex;
  justify-content: center;
}

.react-multiple-carousel__arrow--right {
  z-index: 100 !important;
  @media (max-width: 480px) {
    right: calc(1% - 10px) !important;
  }
}

.react-multiple-carousel__arrow--left {
  z-index: 100 !important;

  @media (max-width: 480px) {
    left: calc(1% - 10px) !important;
  }
}






